import React from "react";
import { Switch, Redirect } from "react-router-dom";

import RouteWithLayout from "./components/RouteWithLayout";
import { Main as MainLayout } from "./layout";

import { Account, Dashboard } from "./views";
import Balance from "views/Balance";

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/dashboard" />
      <RouteWithLayout
        component={Dashboard}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        component={Account}
        exact
        layout={MainLayout}
        path="/account"
      />
       <RouteWithLayout
        component={Balance}
        exact
        layout={MainLayout}
        path="/balance"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
