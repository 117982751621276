import React from 'react';
// import clsx from 'clsx';
import { makeStyles, createStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  Divider,
  Button,
  Theme,
} from '@material-ui/core';

import { Link } from 'react-router-dom';
import MoneyIcon from '@material-ui/icons/EuroSymbol';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
    },
    grid: {
      marginBottom: theme.spacing(2),
    },
    title: {
      fontWeight: 700,
    },
    iconBackgound: {
      backgroundColor: theme.palette.success.main,
      height: 56,
      width: 56,
    },
    icon: {
      height: 32,
      width: 32,
    },
    footer: {
      marginTop: theme.spacing(2),
    }
  })
);

const Balance = () => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid className={classes.grid}  container justify="space-between">
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              CURRENT BALANCE
            </Typography>
            <Typography variant="h4">€6,42</Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.iconBackgound}>
              <MoneyIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>

        <Divider></Divider>

        <div className={classes.footer}>
          <Typography variant="caption">
            <Button component={Link} to="/balance" variant="outlined">
              statistics
            </Button>
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

export default Balance;
