import React from 'react';
import { BrowserRouter as Router, HashRouter } from 'react-router-dom';
import Routes from './Routes';
import theme from './theme'
import { ThemeProvider } from '@material-ui/core';

function App(): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <Router >
        <Routes />
      </Router>
    </ThemeProvider>
  );
}

export default App;
