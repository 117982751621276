import React, { forwardRef } from 'react';

import { makeStyles } from '@material-ui/styles';
import { Grid, createStyles, Theme, Button, Hidden } from '@material-ui/core';
import { NavLink as RouterLink } from 'react-router-dom';
import LatestSales from './components/BalanceGraph';
import CurrentBalance from './components/CurrentBalance';
import BalanceHistory from './components/BalanceHistory';

// import { AccountProfile, AccountDetails, Password } from './components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(4)
    },
  }),
);



const Balance = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={5}
          md={6}
          xl={5}
          xs={12}
        >
          <CurrentBalance />
        </Grid>
        <Hidden smDown>
          <Grid
            item
            lg={5}
            md={6}
            xl={5}
            xs={12}
          >
          </Grid>
        </Hidden>



          <Grid
            item
            lg={5}
            md={6}
            xl={5}
            xs={12}
          >
          <BalanceHistory />

          </Grid>

          <Hidden smDown>
          <Grid
            item
            lg={5}
            md={6}
            xl={5}
            xs={12}
          >
          </Grid>
        </Hidden>

        <Grid
          item
          lg={5}
          md={6}
          xl={5}
          xs={12}
        >
          <LatestSales />

        </Grid>
        <Grid
          item
          lg={8}
          md={6}
          xl={8}
          xs={12}
        >
        </Grid>
      </Grid>
    </div>
  );
};

export default Balance;
