import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Theme } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#efefef',
      boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.10), 0 1px 3px 0 rgba(0, 0, 0, 0.10)',
      color: theme.palette.primary.contrastText,
    },
    title: {
      fontWeight: 700
    },
    icon: {
      color: '#8c8c8c',
      height: 40,
      width: 40,
      paddingRight: theme.spacing(1)
    }
  })
);



const Address = () => {
  const classes = useStyles();

  return (
    <Card
      className={classes.root}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              HOUSE ADDRESS
            </Typography>
            <Typography variant="h4">Schubertlaan 74</Typography>
          </Grid>
          <Grid item>
            <HomeIcon className={classes.icon} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Address;
