import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Theme, createStyles } from '@material-ui/core';
import LatestSales from '../Balance/components/BalanceGraph';
import Address from './components/Address';
import Trash from './components/Trash/Trash';
import Balance from './components/Balance';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(4)
        },
    }),
);



const Dashboard = (): JSX.Element => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid
                container
                spacing={4}
            >
                <Grid
                    item
                    lg={12}
                    sm={12}
                    xl={12}
                    xs={12}
                >
                    
                    <Address />
                </Grid>
                <Grid
                    item
                    lg={3}
                    sm={6}
                    xl={3}
                    xs={12}
                >
                    <Trash/>
                    
                </Grid>
                <Grid
                    item
                    lg={3}
                    sm={6}
                    xl={3}
                    xs={12}
                >
                    <Balance />
                </Grid>
                <Grid
                    item
                    lg={3}
                    sm={6}
                    xl={3}
                    xs={12}
                >
                    {/* <TasksProgress /> */}
                </Grid>
                <Grid
                    item
                    lg={3}
                    sm={6}
                    xl={3}
                    xs={12}
                >
                    {/* <TotalProfit /> */}
                </Grid>
                <Grid
                    item
                    lg={6}
                    md={6}
                    xl={6}
                    xs={12}
                >
                    {/* <LatestSales /> */}
                </Grid>
                <Grid
                    item
                    lg={4}
                    md={6}
                    xl={3}
                    xs={12}
                >
                    {/* <UsersByDevice /> */}
                </Grid>
                <Grid
                    item
                    lg={4}
                    md={6}
                    xl={3}
                    xs={12}
                >
                    {/* <LatestProducts /> */}
                </Grid>
                <Grid
                    item
                    lg={8}
                    md={12}
                    xl={9}
                    xs={12}
                >
                    {/* <LatestOrders /> */}
                </Grid>
            </Grid>
        </div>)
}

export default Dashboard;