import React from 'react';
import { Route } from 'react-router-dom';
// import { BrowserRouter as Router } from 'react-router-dom';


interface Props {
    layout: React.FunctionComponent,
    component: any,
    rest : any
}

const RouteWithLayout: React.FunctionComponent<Props> = props => {
  const { layout: Layout, component: Component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={matchProps => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};

export default RouteWithLayout;
